import {CommerceAPI, ShopperProducts} from 'commerce-api'
import type {RootStore} from './RootStore'
import {makeObservable, flow, observable, action} from 'mobx'
import {OmitFromKnownKeys, StoreModel, Wrapper} from './utils'
import {keysToCamel} from '../utils/utils'
import {TopRightProductBadgeKey} from '../components/cms/product-badges'
import { BaseItem } from "@algolia/autocomplete-core";

type ShopperProduct = ShopperProducts.Product

export interface Promotion {
  ID: string
  basedOnCustomerGroups: string[]
  isMealDealLanding: boolean
  msg: string
  multibuyUnitPrice: string
  req: number
  startDate: string
  t: string
  details?: {}
  finished?: boolean
  groups?: Array<{
    cartQty?: number
    requiredQty?: number
    req?: string[]
    ids?: string[]
    t?: string
  }>
  imageURL?: string
  maxApp?: number
  name?: string
  promoQtyRatio?: number
  remainingQty?: number
  type?: string
  isCompleted?: boolean
  promotionPrice?: number
  buyXReq?: number
  getYQuantity?: number
}

export interface StoreProduct
  extends OmitFromKnownKeys<
    ShopperProduct,
    'variationAttributes' | 'variationValues' | 'variants' | 'productPromotions'
  > {
  productPromotions: {
    multiBuyPromo: Promotion
    nearPromos: Array<Promotion>
  }
  priceInfo: {
    [price: number]: string
    perUnit: string
    wasPrice: string
  }
  price: {
    GBP: number
  }
}

export interface ISearchParams<TRefine = string[]> {
  organizationId?: string
  siteId?: string
  q?: string
  refine?: TRefine
  sort?: string
  currency?: string
  locale?: string
  offset?: any
  limit?: number
}

export interface Hit extends BaseItem, Record<string, unknown> {
  id: string
  name: string
  image_groups?: Array<{
    images?: Array<any>
  }>
  price?: {GBP: number}
  priceInfo?: {
    perUnit: string
    [price: number]: string
    wasPrice: string
  }
  productRating?: number
  pdpTopRightTileBadge?: TopRightProductBadgeKey
  pdpMiddleRightTileBadge?: TopRightProductBadgeKey
  pdpBottomRightTileBadge?: TopRightProductBadgeKey
  pdpBadgeAsset?: string
  productPromotions: {
    multiBuyPromo: Promotion
    nearPromos: Array<Promotion>
  }
  maxOrderQuantity?: number
  isAddToCartDisabled: boolean
  disabledStatus: string | null
  productClasses: string[]
  objectID: string
  availableForDayAtStore: boolean
  __position: number
  __queryID: string
}

export interface TransformedHit extends Hit {
  defaultPrice: string
  dailyPrice: string | null
  displayPrice: string
}

interface InitialState {
  loading: boolean
  productsById: Record<string, ProductModel>
}

export class ProductModel extends Wrapper<StoreProduct>() {
  constructor(product: StoreProduct) {
    super(product)
    makeObservable(this, {
      getImagesForStandardProduct: action.bound,
      getProductPromotionsIds: action.bound,
    })
  }

  getImagesForStandardProduct() {
    if (!this.imageGroups || this.imageGroups.length < 1) {
      return []
    }

    const foundGroup = this.imageGroups.find((group) => group.viewType === 'main')

    const imageSet = foundGroup || this.imageGroups[0]
    const images =
      (imageSet && imageSet.images) || (this.imageGroups[0] && this.imageGroups[0].images)
    return images || []
  }

  getProductPromotionsIds() {
    const promotions = Object.values(this.productPromotions).filter(Boolean)

    if (!promotions.length) return []

    const ids = promotions
      .map((promotion) => (Array.isArray(promotion) ? promotion?.map(({ID}) => ID) : promotion?.ID))
      .flat()
      .filter(Boolean)

    const uniqIds = [...new Set(ids)]

    return uniqIds
  }
}

export class ProductStore implements StoreModel {
  api: CommerceAPI
  rootStore: RootStore
  currentSearchKey?: string | null
  loading: boolean
  productsById: Record<string, ProductModel>

  constructor(
    rootStore: RootStore,
    initialState: InitialState = {
      loading: false,
      productsById: {},
    },
  ) {
    this.loading = initialState.loading
    this.productsById = Object.keys(initialState.productsById || []).reduce((acc, id) => {
      return {...acc, [id]: new ProductModel(initialState.productsById[id])}
    }, {})

    makeObservable(this, {
      fetchProducts: flow.bound,
      storeHitsAsProducts: flow.bound,
      getPdpAwardSlot: flow.bound,
      loading: observable,
      productsById: observable,
    })

    this.api = rootStore.api
    this.rootStore = rootStore
  }

  get asJson() {
    return {
      productsById: this.productsById,
    }
  }

  *fetchProducts(ids: Array<string>, ignoreRange: boolean) {
    try {
      // Filter out ids for products we have already fetched. We may have a partial
      // product in state but still need the full detail. We consider a product with
      // a `variants` field to be fully fetched, so we check for that.
      const filteredIds = ids.filter((id) => {
        const existingProduct = this.productsById[id]
        return !existingProduct
      })

      if (filteredIds.length > 0) {
        const promotionIds: string[] = []

        const productResults: Hit[] = yield this.api.algoliaShopperProduct.getProducts({
          parameters: {ids: filteredIds.toString(), allImages: true, perPricebook: true, ignoreRange:ignoreRange, day: this.rootStore.globalStore.selectedAvailabilityDayOffset, store: this.rootStore.globalStore.selectedStoreId},
        })

        productResults?.forEach((product) => {
          // this check is entirely for Einstein as it's getting products from a different environment (production) - products may not exist in other envs
          if (product?.id) {
            const productModel = new ProductModel({
              ...this.productsById[product.id],
              ...product,
            })

            const productPromotionIds = productModel.getProductPromotionsIds()

            this.productsById[product.id] = productModel
            promotionIds.push(...productPromotionIds)
          }
        })

        yield this.rootStore.promotionStore.fetchPromotionsByIds([...new Set(promotionIds)])
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  *storeHitsAsProducts(products: Array<Hit>) {
    const promotionIds: string[] = []

    for (const product of products) {
      const _product = keysToCamel(product)
      const existingProduct = this.productsById[_product?.id]

      if (_product.id) {
        const productModel = new ProductModel({
          ...this.productsById[_product.id],
          ..._product,
        })

        const productPromotionIds = productModel.getProductPromotionsIds()

        this.productsById[product.id] = productModel
        promotionIds.push(...productPromotionIds)
      }
    }

    yield this.rootStore.promotionStore.fetchPromotionsByIds([...new Set(promotionIds)])
  }

  *getPdpAwardSlot(productId: string, categoryIds: string[]) {
    const {contentStore} = this.rootStore

    if (contentStore.pdpAwardSlots.length === 0) {
      yield contentStore.fetchItemsByContentType( 'https://www.iceland.co.uk/slot/pdp-awards-slot.json', 'pdpAwardSlots')
    }

    // Find content on Product Level first, then if there is no content on Product Level, find content on Category Level
    const relevantProductLevelContent = contentStore.pdpAwardSlots.find((slot) => {
      return slot.productList?.products?.some((product) => product.id === productId)
    })

    if (relevantProductLevelContent) {
      return relevantProductLevelContent
    }

    const relevantCategoryLevelContent = contentStore.pdpAwardSlots.find((slot) => {
      return slot.categoryList?.some((category) => categoryIds.includes(category.id))
    })

    if (relevantCategoryLevelContent) {
      return relevantCategoryLevelContent
    }

    return null
  }
}
