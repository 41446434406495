import type {ShopperCustomers} from 'commerce-api'
import pwaKitConfig from '../pwa-kit.config.json'
import {ErrorWithTextMessage} from '../types/ui'
import {DateUtils} from './date-utils'

/**
 * Call requestIdleCallback in supported browsers.
 *
 * https://developers.google.com/web/updates/2015/08/using-requestidlecallback
 * http://caniuse.com/#feat=requestidlecallback
 */
export const requestIdleCallback = (fn: () => void | IdleRequestCallback) => {
  if ('requestIdleCallback' in window) {
    return window.requestIdleCallback(fn)
  } else {
    return setTimeout(() => fn(), 1)
  }
}

/**
 * Compares the primary fields of two address objects to determine if they match.
 */
type AddressParams = Partial<ShopperCustomers.OrderAddress & ShopperCustomers.CustomerAddress>
export const isMatchingAddress = (addr1: AddressParams, addr2: AddressParams) => {
  const normalize = (addr: AddressParams) => {
    // eslint-disable-next-line no-unused-vars
    const {id, addressId, _type, preferred, creationDate, lastModified, ...normalized} = addr
    return normalized
  }
  return shallowEquals(normalize(addr1), normalize(addr2))
}

/**
 * Performs a shallow comparison on two objects
 */
export const shallowEquals = (a: Record<string, any>, b: Record<string, any>) => {
  for (const key in a) {
    if (!(key in b) || a[key] !== b[key]) {
      return false
    }
  }
  for (const key in b) {
    if (!(key in a) || a[key] !== b[key]) {
      return false
    }
  }
  return true
}

/**
 * No operation function. You can use this
 * empty function when you wish to pass
 * around a function that will do nothing.
 * Usually used as default for event handlers.
 */
export const noop = () => {}

/**
 * Flattens a tree data structure into an array.
 */
export const flatten = (node: Record<string, any>, key = 'children') => {
  const children = (node[key] || []).reduce((a: any, b: any) => {
    return Array.isArray(b[key]) && !!b[key].length ? {...a, ...flatten(b, key)} : {...a, [b.id]: b}
  }, {})

  return {
    [node.id]: node,
    ...children,
  }
}

/**
 * Check the current execution environment
 * is client side or server side
 */
export const isServer = typeof window === 'undefined'

/**
 * Capitalizes the words in a string
 */
export const capitalize = (text: string) => {
  return text
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export const getConfig = () => pwaKitConfig

export const getUrlConfig = () => getConfig().url

function isErrorWithTextMessage(error: unknown): error is ErrorWithTextMessage {
  return (
    typeof error === 'object' &&
    error != null &&
    !!(error as Record<string, unknown>)?.message &&
    typeof (error as Record<string, unknown>)?.message === 'string'
  )
}

export function formatStatusDetails(details: string) {
  // Details should be in the format response={data}
  // This removes the response string and brackets leaving the data to be read
  return details.replace('response={', '').slice(0, -1)
}

/**
 * Checks if string can be parsed as JSON
 * If can be parsed returns the JSON Object if not returns null
 */
export function isJSONString(str: string | null) {
  if(str === null) {
    return str
  } 

  try {
    return JSON.parse(str)
  } catch (e) {
    return null
  }
}

/**
 * Given an object (response from email validation API) see if it has
 * a typo property. If it does return a formatted message
 */
export function getPossibleTypo(obj: Record<string, unknown>) {
  if (obj && Object.hasOwn(obj, 'typo') && obj.typo) {
    let msg = 'Please check this value, are you sure this is correct?';
    if (Object.hasOwn(obj, 'typoSuggestion')){
      msg = `Did you mean? ${obj.typoSuggestion}`;
    }
    return msg;
  }
  return null;
}

export function getErrorMessage(error: unknown, isTroubleLoggingInEmailEnabled?: boolean): string {
  if (isErrorWithTextMessage(error)) return error.message
  if ((error as any).error === 'LoginNotUniqueException') {
    const resetLink = `<a href="/login" style="text-decoration:underline;">reset your password</a>`;
    const message = isTroubleLoggingInEmailEnabled
      ? `you’ll receive an e-mail to reset your password`
      : `please ${resetLink}`;
    return `Sorry, your account could not be created. If you already have an account, ${message}.`;
  }
  return "Something's not right, please check and try again."
}

export function getFormattedDate(dateString: string) {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = (1 + date.getMonth()).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return `${day}/${month}/${year}`
}

const toCamel = (str: string) => {
  if (str.startsWith('_') || str.startsWith('c_')) {
    return str
  }
  return str.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

const isObject = (obj: any) => {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function'
}

export const keysToCamel = (obj: any): any => {
  if (isObject(obj)) {
    const n: Record<string, any> = {}

    Object.keys(obj).forEach((k) => {
      n[toCamel(k)] = keysToCamel(obj[k])
    })

    return n
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return keysToCamel(i)
    })
  }

  return obj
}

export const getSecondsLeft = (date: string) => {
  const t1 = DateUtils.current()
  const t2 = DateUtils.utcToZonedDate(date)
  return Math.round((t2.getTime() - t1.getTime()) / 1000)
}

// prepend a zero onto a string/number for display (like a date or timer)
export const prependZero = (val: number | string) => (String(val).length < 2 ? '0' + val : val)

// Pointer helps to determine click and click & drag mouse event.
// Can be used with mouseup/mousedown event listeners.
export function Pointer(threshold = 10) {
  let x = 0
  let y = 0

  return {
    start(e: MouseEvent) {
      x = e.clientX
      y = e.clientY
    },

    getCoords() {
      return {x, y}
    },

    reset() {
      x = 0
      y = 0
    },

    isDragEvent(e: MouseEvent) {
      if (x === 0 && y === 0) return false

      const deltaX = Math.abs(e.clientX - x)
      const deltaY = Math.abs(e.clientY - y)

      return deltaX >= threshold || deltaY >= threshold
    },
  }
}

export const escapeStringForRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export const validateDate = (date: Date) => {
  return {
    // If the date object is invalid it
    // will return 'NaN' on getTime()
    // and NaN is never equal to itself.
    isValid: date.getTime() === date.getTime(),
  }
}

export const deepCopy = <T, U = T extends Array<infer V> ? V : never>(source: T): T => {
  if (Array.isArray(source)) {
    return source.map((item) => deepCopy(item)) as T & U[]
  }
  if (source instanceof Date) {
    return new Date(source.getTime()) as T & Date
  }
  if (source && typeof source === 'object') {
    return (Object.getOwnPropertyNames(source) as (keyof T)[]).reduce<T>(
      (o, prop) => {
        Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!)
        o[prop] = deepCopy(source[prop])
        return o
      },
      Object.create(Object.getPrototypeOf(source)),
    )
  }
  return source
}

/**
 * Utility provides same functionality as Object.keys does,
 * however collection of Object.keys call result doesn't have typed keys: any element will be string,
 * this util can be used to get exact type of object keys during iteration of Object.keys collection.
 */
export const objectKeys = <Obj extends Object>(obj: Obj): (keyof Obj)[] =>
  Object.keys(obj) as (keyof Obj)[]

/**
 * Provides ordinal suffix for day number.
 * Based on Intl.PluralRules.
 */
export const getDayNumberWithOrdinalSuffix = (day: number): string => {
  const suffixes = {
    zero: 'th',
    many: 'th',
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  }
  const ordinalRules = new Intl.PluralRules('en', {type: 'ordinal'})

  const category = ordinalRules.select(day)
  const suffix = suffixes[category]

  return day + suffix
}

/**
 * Get currency symbol based on provided currency and locale (default to en-GB)
 */
export const getCurrencySymbol = (currency: string, locale = 'en-GB') => {
  if (!currency) return ''

  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()
}

/**
 * Match user agent to representation of device type.
 * @param userAgentString User Agent string to evaluate
 * @returns
 */
export const getDeviceType = (userAgentString: string | undefined | null) => {
  if (!userAgentString) return

  userAgentString = userAgentString.toLowerCase()

  const iPhoneDevice = 'iphone'
  const iPadDevice = 'ipad'
  const androidDevice = 'android'

  if (userAgentString.includes(iPadDevice)) return 'tablet'

  if (
    (userAgentString.includes(androidDevice) && userAgentString.includes('mobile')) ||
    userAgentString.includes(iPhoneDevice)
  ) {
    return 'mobile'
  }

  return 'desktop'
}

export const isValidVSEDomain = (value: unknown) => {
  return typeof value === 'string' && value.trim().toLowerCase().endsWith('staging.bigcontent.io')
}

/**
 * Checks if page is viewed from within mobile app and returns OS if applicable
 * @returns {String | Null} Returns the platform name, or null if no matches
 */
export const getMobileAppOS = () => {
  try {
    const userAgent = window?.navigator?.userAgent
    const androidUserAgentRegex = /^Iceland(?: QA)*\/([\d.]*) Dalvik\/\d+(.\d)+.*/i;
    const iOSUserAgentRegex = /^Iceland(?: QA)*\/([\d.]*) \(iOS (?:[\d.]*)\)/i;
    // Check if Android OS & app Interface exists
    if (androidUserAgentRegex.test(userAgent) && 'appInterface' in window && window.appInterface != undefined) {
      return "Android"
    }
    // Check if IOS & web kit exists
    else if (iOSUserAgentRegex.test(userAgent) && 'webkit' in window && window.webkit != undefined) {
      return "iOS"
    }  
  } catch (e) {
    // Not in App, ignore
    return null
  }
  return null
}

// Find out the user device:
export const getMobileDeviceOS = () => {
  if (typeof navigator !== 'undefined') {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      return "Android"
    }
    if (/iPad|iPhone|iPod|iOS/.test(userAgent)) {
      return "iOS"
    }
  }
  return "unknown"
};

// Format URL to remove special characters

export function formatProductName(name: string | undefined) {
  if (name === undefined || name === null) return
    return encodeURI(name
      .replace(/ /g, '-') // Replace spaces with hyphens
      .replace(/&/g, 'and') // Replace '&' with 'and'
      .replace(/'/g, '') // Remove apostrophes
      .replace(/%/g, '%25') // Encode %
      .toLowerCase() // change all letters to lowercase
    )
}

// Function to detect browser
export function detectBrowser() {
  if (typeof navigator !== 'undefined') {
    const userAgent = navigator.userAgent
  // Check if browser is Safari
    if (userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('Chrome') === -1) {
      return 'Safari'
    }
    return 'Other'
  }
}

export const isMobile = (): boolean => {
  return typeof window !== 'undefined' && window.innerWidth <= 768
}

export const formatHyphenatedString = (str: string): string | undefined => {
  if (!str) return
  return str
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const getDisabledButtonText = (disabledStatus: string | null): string => {
  if (disabledStatus && typeof disabledStatus === 'string') {
    return formatHyphenatedString(disabledStatus) ?? 'Out of Stock'
  }
  return 'Out of Stock'
}

function handleClickOutsideOfPopovers(event: MouseEvent | TouchEvent, isOpen: boolean, setIsOpen: React.Dispatch<React.SetStateAction<boolean>>, popoverClassName: string) {
  const target = event.target as HTMLElement;
  // Close popover if the click is outside
  if (!target.closest(`.${popoverClassName}`) && isOpen) {
    setIsOpen(false)
  }
}

export function handleClosePopoverEvents(isOpen: boolean, setIsOpen: React.Dispatch<React.SetStateAction<boolean>>, popoverClassName: string) {
  document.addEventListener('mousedown', (e) => { handleClickOutsideOfPopovers(e, isOpen, setIsOpen, popoverClassName) })
  document.addEventListener('touchstart', (e) => { handleClickOutsideOfPopovers(e, isOpen, setIsOpen, popoverClassName) })

  return () => {
    document.removeEventListener('mousedown', (e) => { handleClickOutsideOfPopovers(e, isOpen, setIsOpen, popoverClassName) })
    document.removeEventListener('touchstart', (e) => { handleClickOutsideOfPopovers(e, isOpen, setIsOpen, popoverClassName) })
  }
}

// Encode ArrayBuffer to Hex
export function hexEncode(data: ArrayBuffer) {
  const hashArray = new Uint8Array(data)
  return Array.from(hashArray, byte => byte.toString(16).padStart(2, '0')).join('')
}